import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import Head from 'components/Layout/Head/Head';

interface ISharePage extends PageProps {
  pageContext: {
    text: string;
    background: string;
    index: number;
  }
}

const SharePage: FC<ISharePage> = ({
  pageContext: {
    // text,
    background,
    index,
  },
}) => {
  if (typeof window !== 'undefined') {
    window.location.href = `https://holograktor.com/#${index}`;
  }

  return (
    <Head
      // description={text}
      preview={background}
      url={`https://holograktor.com/share/${index}`}
    />
  );
};

export default SharePage;
